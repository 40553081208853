<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mregla-modals :modelName="modelName"></mregla-modals>
	</div>
</template>

<script>
import mreglaActions from './MreglaActions';
import mreglaModals from './MreglaModals.vue';

export default {
	name: 'mregla-grid',
	components: {
		'mregla-modals': mreglaModals
	},
	data() {
		return {
			modelName: 'mregla',
			actions: mreglaActions.gridactions,
			modelColumnDefs: {
				articulo15: {
					render: data => {
						if (data === '1') {
							return this.returnYes;
						} else {
							return this.returnNo;
						}
					}
				},
				activa: {
					render: data => {
						if (data === '1') {
							return this.returnYes;
						} else {
							return this.returnNo;
						}
					}
				},
				indexcluirbuqcisterna: {
					render: data => {
						if (data === '1') {
							return this.returnYes;
						} else {
							return this.returnNo;
						}
					}
				}
			}
		};
	},
	computed: {
		returnYes() {
			return this.$t('yes');
		},

		returnNo() {
			return this.$t('no');
		}
	}
};
</script>
