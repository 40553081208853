<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
	</div>
</template>

<script>
export default {
	name: 'mregla-modals',
	data() {
		return {};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {}
};
</script>
